@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400..900&display=swap');

* {
  box-sizing: border-box;
}

html, body {
  background: #041c3c;
  color:white;
  font-size:14px;

  @media all and (min-width:768px) {
    font-size:16px;
  }
}

.App {
  font-family: "Orbitron", sans-serif;
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
  height:100vh;
  width:100vw;
  overflow:auto;
}

.lang-buttons {
  position:relative;
  z-index:2;
}

.logo {
  height: 30vh;
  pointer-events: none;
  position:absolute;
  top:0;
  right:0;

  @media all and (min-height:600px) {
    height: 40vh;
  }

  @media all and (min-height:800px) {
    height: 50vh;
  }
}

.position-center {
  position:absolute;
  margin:auto;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
  padding:1em;
  width:100%;
  display:flex;
  flex-direction:column;
  align-items:center;
}

.title {
  position:relative;
  text-align:center;
}

h1, .h1 {
  font-weight:normal;
  font-size:2rem;
  line-height:1;
  text-shadow: 0 0 6px white;
  text-transform:uppercase;
  margin:0 0 1.5rem;

  @media all and (min-width:480px) {
    font-size:3rem;
  }

  @media all and (min-width:768px) {
    font-size:5rem;
  }
}

.title-background {
  position:absolute;
  text-shadow: 0 6px 6px black;
  left: 0;
  top: 0;
  right: 0;
  margin: auto;
}

.btns {
  display:flex;
  justify-content:space-between;
  flex-direction:column;
  align-items:center;
  gap:1rem;
  width:300px;

  @media all and (min-width:480px) {
    width:400px;
    gap:2rem;
  }

  @media all and (min-width: 768px) {
    width:760px;
    flex-direction:row;
  }

  @media all and (min-width: 1024px) {
    width:960px;
    gap:5rem;
  }
}

.btn-large {
  font-size:1.5rem;
  font-weight:bold;
  line-height:1;
  border-radius:3rem;
  text-align:center;
  width:100%;
  color:inherit;
  text-decoration:none;
  font-family:sans-serif;
  aspect-ratio: 614/143;
  position:relative;
  overflow:hidden;
  background-position: center;
  background-repeat: no-repeat;

  @media all and (min-width:480px) {
    font-size:2rem;
  }

  @media all and (min-width:768px) {
    font-size:3rem;
  }

  &:after {
    content: "";
    position:absolute;
    z-index:1;
    top:0;
    right:0;
    bottom:0;
    left:0;
    background:transparent;
  }

  &:hover, &:focus {
    &:after {
      background:rgba(255, 255, 255, 0.2);
    }
  }
}

.btn-blue {
  background: rgb(0,66,255);
  background: linear-gradient(90deg, rgba(0,66,255,1) 0%, rgba(88,163,255,1) 50%, rgba(1,66,254,1) 100%);
  background-image: url('/public/btn-blue.png');
  background-size: cover;
}

.btn-orange {
  background: rgb(171,58,18);
  background: linear-gradient(90deg, rgba(171,58,18,1) 0%, rgba(200,152,161,1) 50%, rgba(159,127,201,1) 100%);
  background-image: url('/public/btn-orange.png');
  background-size: cover;
}

.btn-inner {
  position:absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  z-index:2;
}
  
.App.fr {
  @media (min-width: 768px) {
    h1, .h1 {
      font-size: 4rem;
    }
  }

  .btn-large {
    font-size:1.5rem;

    @media all and (min-width:480px) {
      font-size:2rem;
    }
  }
}

.btn-light {
  --bs-btn-disabled-color: #041c3c;
}

.btn-outline-light {
  --bs-btn-hover-color: #041c3c;
  --bs-btn-focus-color: #041c3c;
}